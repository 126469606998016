import React from 'react'

import {
  Copyright,
  SocialIconLink,
  StyledAssetIconFacebookSVG,
  StyledAssetIconInstagramSVG,
  SocialContainer,
} from './SocialCopyright.styled'

export const SocialCopyright = ({ children, ...props }) => (
  <div {...props}>
    <SocialContainer>
      <SocialIconLink
        to="https://www.instagram.com/kalapawai_hawaii/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledAssetIconInstagramSVG />
      </SocialIconLink>
      <SocialIconLink
        to="https://www.facebook.com/kalapawaimarket/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledAssetIconFacebookSVG />
      </SocialIconLink>
    </SocialContainer>
    <Copyright>&copy; {new Date().getFullYear()} Kalapawai Market</Copyright>
    <Copyright>
      Site Design & Development by{' '}
      <span>
        <a
          href="https://walltowall.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wall-to-Wall Studios
        </a>
      </span>
    </Copyright>
  </div>
)
