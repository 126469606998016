import React from 'react'

import { shopifyBuildUrl } from 'src/helpers'
import { Link } from 'src/components/Shared/Link/Link'
import { MenuContainer } from './Footer.styled'

export const Menu = ({ children, ...props }) => (
  <MenuContainer>
    <ul>
      <li>
        <Link to={shopifyBuildUrl('/gift-cards/')}>Gift Cards</Link>
      </li>
      <li>
        <Link to='https://shop.kalapawaimarket.com/'>Shop</Link>
      </li>
      <li>
        <Link to={shopifyBuildUrl('/about-us/')}>About</Link>
      </li>
      <li>
        <Link to={shopifyBuildUrl('/employment/')}>Employment</Link>
      </li>
      <li>
        <Link to={shopifyBuildUrl('/contact/')}>Get in Touch</Link>
      </li>
    </ul>
  </MenuContainer>
)
