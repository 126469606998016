import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash/fp'

import { SubscribeContainer } from './Footer.styled'
import { SubscribeButton, Heading } from './Footer.styled'
import { SocialCopyrightDesktop } from './SocialCopyright.styled'

export const Center = ({ children, ...props }) => {
  const queryData = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          newsletter_sign_up_url {
            url
          }
        }
      }
    }
  `)
  const newsletterSignUpHref = get(
    'prismicSettings.data.newsletter_sign_up_url.url',
    queryData
  )

  return (
    <SubscribeContainer
      id="subscription"
      action="https://kalapawaicafe.cmail1.com/s/204326/"
      method="post"
    >
      <Heading>Get the Latest Newsletter</Heading>
      <SubscribeButton href={newsletterSignUpHref} target="_blank">
        Subscribe Now
      </SubscribeButton>
      <SocialCopyrightDesktop />
    </SubscribeContainer>
  )
}
