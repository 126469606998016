import React from 'react'

import CatPng from 'src/assets/sleeping-cat-static.png'
import CatGif from 'src/assets/sleeping-cat3.gif'
import { FooterContainer, StyledCat, PalaKaPattern } from './Footer.styled'
import { SocialCopyrightMobile } from './SocialCopyright.styled'
import { Locations } from './Locations'
import { Center } from './Center'
import { Menu } from './Menu'

export class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      catImage: CatPng,
    }
  }

  render() {
    return (
      <>
        <PalaKaPattern />
        <FooterContainer>
          <Locations />
          <Center />
          <Menu />
          <SocialCopyrightMobile />
          {this.props.showCatImage && (
            <StyledCat
              src={this.state.catImage}
              onMouseEnter={e => this.setState({ catImage: CatGif })}
              onMouseLeave={e => this.setState({ catImage: CatPng })}
            />
          )}
          <script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript" />
          <script 
            type="text/javascript" 
            dangerouslySetInnerHTML={{ __html: `
                ttd_dom_ready( function() {
                    if (typeof TTDUniversalPixelApi === 'function') {
                        var universalPixelApi = new TTDUniversalPixelApi();
                        universalPixelApi.init("2dqtjic", ["wfyzaji"], "https://insight.adsrvr.org/track/up");
                    }
                });
            `}}
          />
        </FooterContainer>
      </>
    )
  }
}
