import styled from 'react-emotion'

import t from 'src/theme'
import { Button } from 'src/components/Shared/Button/Button'
import { Link } from 'src/components/Shared/Link/Link'
import { ReactComponent as AssetIconFacebookSVG } from 'src/assets/icon-facebook.svg'
import { ReactComponent as AssetIconInstagramSVG } from 'src/assets/icon-instagram.svg'

export const SubscribeInput = styled.input`
  appearance: none;
  background-color: ${t.c.green};
  border-radius: 3px 3px 0 0;
  border: solid 1px ${t.c.lightGreen};
  color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-1)};
  padding: ${t.s(-2)};
  text-align: center;
  width: 100%;
  margin-bottom: 0;

  &::placeholder {
    color: ${t.c.lightGreen};
    text-align: center;
    font-style: italic;
  }
`
const StyledButton = styled(Button)`
  appearance: none;

  color: ${t.c.green};
  display: block;
  background-color: ${t.c.white};
  // border: solid 1px ${t.c.lightGreen};
  border-radius: 3px;
  text-align: center;
  width: 100%;
  padding: ${t.s(-2)};
  font-size: ${t.f(-1)};
  font-family: ${t.ff.monoBody};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 0;
  text-decoration: none;

  a {
    color: white !important;
    text-transform: none;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline
  }
`

export const SubscribeButton = StyledButton.withComponent(Link)

export const PalaKaPattern = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYyLjU1IiBoZWlnaHQ9Ijc5LjU1IiB2aWV3Qm94PSIwIDAgMTYyLjU1IDc5LjU1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGQ9Im0wIDBoMTYyLjU1djc5LjU1aC0xNjIuNTV6Ii8+PC9jbGlwUGF0aD48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGQ9Im0wIDQyLjIzaDE2Mi41NXY3OC42M2gtMTYyLjU1eiIgZmlsbD0iIzAwNjY0NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDE2Mi41NSAxNjMuMSkiLz48cGF0aCBkPSJtMCA0Mi42aDgyLjI4djc4LjI3aC04Mi4yOHoiIGZpbGw9IiNmZmYiIG9wYWNpdHk9Ii42IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgLTEgODIuMjggMTYzLjQ3KSIvPjxwYXRoIGQ9Im0wLTY4Ljc4aDE2Mi41NXYxMTEuOTRoLTE2Mi41NXoiIGZpbGw9IiMxMjQ3MzQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAtMSAxNjIuNTUgLTI1LjYyKSIvPjxwYXRoIGQ9Im0wLTY4Ljc4aDgyLjI4djExMS45NGgtODIuMjh6IiBmaWxsPSIjNGNhNTg1IiBvcGFjaXR5PSIuNSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDgyLjI4IC0yNS42MikiLz48ZyBmaWxsPSJub25lIiBzdHJva2U9IiMxMjQ3MzQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIzIj48cGF0aCBkPSJtMTYyLjU1IDYwLjczaC0xNjIuNTUiLz48cGF0aCBkPSJtNjMuNzIgMTIwLjg3di0xODkuNjUiLz48cGF0aCBkPSJtNDEuMTQgMTIwLjg3di0xODkuNjUiLz48cGF0aCBkPSJtMTguNTYgMTIwLjg3di0xODkuNjUiLz48L2c+PC9nPjwvc3ZnPgo=);
  height: 39px;
  background-size: auto 100%;
  background-repeat: repeat-x;
  border-bottom: 1px solid #006646;
`

export const FooterContainer = styled.div`
  position: relative;
  color: ${t.c.white};
  padding: ${t.s(3)} 0;
  background-color: ${t.c.green};
  font-family: ${t.ff.monoBody};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${t.mq.m} {
    background: ${t.c.green};
    flex-direction: row;
  }
`

export const LocationsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${t.mq.s} {
    width: 30%;
  }

  ul {
    padding: 0;
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    margin-bottom: ${t.s(3)};
    letter-spacing: 0.075rem;
    li {
      a {
        color: ${t.c.white};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      list-style: none;
      margin-bottom: ${t.s(-1)};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${t.mq.m} {
    ul {
      margin-bottom: 0;
    }
  }
`

export const SubscribeContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: ${t.s(3)};
  min-width: ${t.s(8)};

  ${t.mq.m} {
    margin-bottom: 0;
  }

  input[type='text'] {
    font-size: ${t.f(-1)};
    text-align: center;
    background-color: ${t.c.green};
    color: ${t.c.white};
    border: solid 1px ${t.c.lightGreen};
    border-radius: 3px 3px 0 0;
    width: 100%;
    padding: ${t.s(-2)};

    &::placeholder {
      color: ${t.c.lightGreen};
      text-align: center;
      font-style: italic;
    }
  }
`

export const MenuContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  margin-bottom: ${t.s(3)};
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${t.f(-1)};
  ${t.mq.s} {
    width: 30%;
  }

  ul {
    letter-spacing: 0.075rem;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        color: ${t.c.white};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      list-style: none;
      margin-bottom: ${t.s(-1)};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${t.mq.m} {
    margin-bottom: 0;
  }
`
export const Copyright = styled.div`
  color: ${t.c.lightGreen};
`

const SocialIcon = styled.svg`
  height: ${t.s(1)};
  width: ${t.s(1)};
  margin: ${t.s(1)};

  path {
    fill: ${t.c.tan};
    transition: fill ${t.t};
  }

  &:hover {
    path {
      fill: ${t.c.lightGreen};
    }
  }
`

export const SisterSection = styled.div`
  margin: ${t.s(1)} 0 ${t.s(-2)};
  font-size: ${t.f(-2)};
  color: ${t.c.lightGreen};
  text-transform: none;
  text-align: center;
`

export const StyledCat = styled.img`
  position: absolute;
  top: -124px;
  right: -8%;
  width: 220px;
  display: block;
  z-index: 2;

  ${t.mq.m} {
    top: -164px;
    right: 15%;
    width: 320px;
  }
`

export const StyledAssetIconFacebookSVG = SocialIcon.withComponent(
  AssetIconFacebookSVG
)

export const StyledAssetIconInstagramSVG = SocialIcon.withComponent(
  AssetIconInstagramSVG
)

export const Heading = styled.p`
  margin-bottom: ${t.s(1)};
  text-align: center;
`
