import React from 'react'

import { shopifyBuildUrl } from 'src/helpers'
import { LocationsContainer } from './Footer.styled'

export const Locations = ({ children, ...props }) => (
  <LocationsContainer>
    <ul>
      <li>
        <a href={shopifyBuildUrl('/kailua-beach/')}>Kailua Beach</a>
      </li>
      <li>
        <a href={shopifyBuildUrl('/kailua-town/')}>Kailua Town</a>
      </li>
      <li>
        <a href={shopifyBuildUrl('/kapolei/')}>Kapolei</a>
      </li>
      <li>
        <a href={shopifyBuildUrl('/waimanalo/')}>Waimanalo</a>
      </li>
    </ul>
  </LocationsContainer>
)
