import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const ButtonContainer = styled(Link)`
  background-color: ${t.c.lightGreen};
  border-radius: 3px;
  color: ${t.c.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: ${t.s(-1)} ${t.s(4)};
  text-align: center;
  text-transform: uppercase;
  transition-duration: ${t.t};
  transition-property: background-color, color;
  text-decoration: none;

  &:hover {
    background-color: ${t.c.green};
    color: ${t.c.white};
    text-decoration: none !important;
  }
`
