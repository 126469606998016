import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'
import { ReactComponent as AssetIconFacebookSVG } from 'src/assets/icon-facebook.svg'
import { ReactComponent as AssetIconInstagramSVG } from 'src/assets/icon-instagram.svg'
import { SocialCopyright } from './SocialCopyright'

export const SocialCopyrightDesktop = styled(SocialCopyright)`
  display: none;
  text-align: center;
  ${t.mq.m} {
    display: block;
  }
`
export const SocialCopyrightMobile = styled(SocialCopyright)`
  display: block;
  text-align: center;
  padding: 0 ${t.s(3)}};
  ${t.mq.m} {
    display: none;
  }
`

export const Copyright = styled.div`
  color: ${t.c.lightGreen};
  font-size: ${t.f(-1)};
  margin-bottom: ${t.s(-2)};

  a {
    color: ${t.c.white};
    white-space: nowrap;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SocialIconLink = styled(Link)`
  margin: ${t.s(1)} ${t.s(-2)};
`

const SocialIcon = styled.svg`
  height: ${t.s(1)};
  width: ${t.s(1)};

  path {
    fill: ${t.c.tan};
    transition: fill ${t.t};
  }

  &:hover {
    path {
      fill: ${t.c.lightGreen};
    }
  }
`

export const StyledAssetIconFacebookSVG = SocialIcon.withComponent(
  AssetIconFacebookSVG
)

export const StyledAssetIconInstagramSVG = SocialIcon.withComponent(
  AssetIconInstagramSVG
)
